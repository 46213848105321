<template>
  <JetDialogData
    title="Сформировать группу"
    width="900"
    v-model="dialog"
    :current-tab="currentTab"
  >
    <!-- Основной таб -->
    <template v-slot:tab-1>
      <!-- Выбор оператора -->
      <v-layout column>
        <!-- Строка с выбором оператора -->
        <v-flex>
          <v-layout class="py-2">
            <!-- Пустое пространство (отступ) -->
            <v-flex></v-flex>

            <v-flex shrink>
              <v-layout>
                <!-- Выбор оператора -->
                <v-flex>
                  <v-select
                    hide-details return-object clearable
                    class="pt-0"
                    style="width: 400px;"
                    label="Оператор"
                    item-text="useridTitle"
                    item-value="userid"
                    v-model="operators.selected"
                    :items="operators.items"
                  ></v-select>
                </v-flex>

                <!-- Добавить маршрут -->
                <v-flex>
                  <v-btn
                    class="ml-4"
                    color="primary"
                    :disabled="operators.selected == null"
                    @click="onAddRouteClick"
                  >
                    Добавить маршрут
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <!-- Строка с Таблицей -->
      <v-layout>
        <v-flex>
          <v-data-table
            height="400"
            :items="table.data"
            :headers="table.headers"
          >
            <!-- Действия -->
            <template v-slot:item.actions="{ item }">
              <!-- Удаление записи -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab x-small depressed
                    v-on="on"
                    v-bind="attrs"
                    @click="onRemoveRouteGroup(item)"
                  >
                    <v-icon color="red">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>

                Удалить
              </v-tooltip>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <!-- Строка с "Сохранить" -->
      <v-layout>
        <v-flex></v-flex>

        <v-flex shrink>
          <v-btn
            color="primary"
            :loading="saving"
            :disabled="saving || operators.selected == null"
            @click="onSaveClick"
          >
            Сохранить
          </v-btn>
        </v-flex>
      </v-layout>
    </template>

    <!-- Список маршрутов -->
    <template v-slot:tab-2>
      <v-layout column>
        <!-- Таблица всех маршрутов -->
        <v-flex>
          <v-row>
            <v-spacer />

            <v-col>
              <v-text-field
                v-model="searchText"
                label="Маршрут"
                placeholder="Номер или название"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                v-model="searchTextCarrier"
                label="Обслуживающий перевозчик"
                placeholder="Название"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            show-select
            height="400"
            v-model="routes.selected"
            :items="filtered_list"
            :headers="routes.headers"
          ></v-data-table>
        </v-flex>

        <!-- Кнопки действия -->
        <v-flex class="mt-4">
          <v-layout>
            <v-flex></v-flex>

            <v-flex shrink>
              <v-btn color="primary" @click="onRoutesSelect">
                Выбрать
              </v-btn>

              <v-btn color="primary" class="ml-4" @click="onRoutesCancel">
                Отмена
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </JetDialogData>
</template>

<script>
// Сторонние зависимости
import md5 from 'blueimp-md5';

// Миксины
import BaseAction from '@/components/actions/BaseAction';

// Компоненты
import JetDialogData from '@/components/JetDialogData';

// Утилиты
import {httpParam, prepareSinRequest, prepareSinResponse} from '@/utils/http';
import {findObjectIndex, uuidv4} from '@/utils/utils';

// Идентификатор view операторов
const _OPERATOR_VIEW_GUID = 'sin2:/v:4f020fa3-eb3a-4831-9cae-be920ea1b139';
// Идентификатор view списка маршрутов
// const _ROUTES_VIEW_GUID = 'sin2:/v:542332ad-a0fa-4982-bff8-7d25816ca1fe';
const _ROUTES_VIEW_GUID = 'sin2:/v:d122d351-3e3e-4983-a73c-199f0fa1bed5';
// Идентификатор view списка маршрутов группы
const _GROUP_ROUTES_VIEW_GUID = 'sin2:/v:08673750-bc12-4a2e-b06b-9126933536fb';
// Идентификатор view оператора группы
const _GROUP_OPERATOR_VIEW_GUID = 'sin2:/v:99b07dcb-3e98-4a32-a4a2-283146ac4d79';

// Основной таб
const _MAIN_TAB = 0;
// Добавление маршрутов таб
const _ADD_ROUTE_TAB = 1;

export default {
  components: {
    JetDialogData,
  },
  mixins: [
    BaseAction,
  ],
  name: 'FormGroup',
  data: () => ({
    // Флаг для отображения диалога
    dialog: false,
    // Флаг что происходит сохранение
    saving: false,
    // Строка поиска по маршруту
    searchText: '',
    // Строка поиска по перевозчику
    searchTextCarrier: '',
    // Текущая активная вкладка
    currentTab: 0,
    // Настройка главной таблицы
    table: {
      // Описание заголовков
      headers: [
        {text: '№', value: 'num', width: '50'},
        {text: 'Название', value: 'name'},
        {text: '', value: 'actions', sortable: false, width: '50'},
      ],
      // Данные
      data: [],
    },
    // Операторы
    operators: {
      // Список операторов
      items: [],
      // Выбранный элемент
      selected: null,
      // Флаг что данные загружаются
      loading: false,
      // Оператор с бекенда
      exist: null,
    },
    // Маршруты
    routes: {
      // Список маршрутов
      items: [],
      // Описание заголовков
      headers: [
        {text: '№', value: 'routecode'},
        {text: 'Название', value: 'routename'},
        {text: 'Перевозчик', value: 'carriers'},
      ],
      // Выбранные маршруты
      selected: [],
      // Флаг что данные загружаются
      loading: false,
    },
    // Маршруты группы
    groupRoutes: {
      // Признак что происходит загрузка
      loading: false,
    },
  }),
  mounted() {
    if (!this.selected) {
      return;
    }

    this.dialog = true;

    this.loadData();
  },
  computed: {
    filtered_list() {
      if (!this.searchText && !this.searchTextCarrier) {
        return this.routes.items;
      }

      const re1 = new RegExp(this.searchText, 'i');
      const re2 = new RegExp(this.searchTextCarrier, 'i');

      return this.routes.items.filter(item => (re1.test(item.routecode) || re1.test(item.routename)) && re2.test(item.dspcarriers));
    }
  },
  methods: {
    // Загрузка данных
    async loadData() {
      this.groupRoutes.loading = true;
      this.operators.loading = true;
      this.routes.loading = true;

      // Загрузка операторов
      prepareSinRequest('core-read', _OPERATOR_VIEW_GUID)
        .then(request => {
          const response = prepareSinResponse(request, {
            hideUnderscores: true,
          });

          this.operators.items = _copy(response);
        })
        .catch(err => {
          console.error('FormGroup::loadOperators', err);
        })
        .finally(() => {
          this.operators.loading = false;
        });

      // Загрузка маршрутов
      prepareSinRequest('core-read', _ROUTES_VIEW_GUID)
        .then(request => {
          const response = prepareSinResponse(request, {
            hideUnderscores: true,
          });

          this.routes.items = _copy(response);
        })
        .catch(err => {
          console.error('FormGroup::loadVehicles', err);
        })
        .finally(() => {
          this.routes.loading = false;
        });

      // Загрузка списка маршрутов группы

      let query = _GROUP_ROUTES_VIEW_GUID;

      query += `?filter=`;
      query += `eq(field(".routeGroupId"), param("${this.selected.dsproutegroupsId}", "id"))`;

      await this.$store.dispatch('cache/removeItem', md5('core-read' + query));

      prepareSinRequest('core-read', query)
        .then(request => {
          const response = prepareSinResponse(request, {
            hideUnderscores: true,
          });

          this.table.data = _copy(response).map(it => {
            return {
              num: it.routeidRoutecode,
              name: it.routeidRoutename,
              id: it.id,
              isNew: false,
            };
          });
        })
        .catch(err => {
          console.error('FormGroup::loadGroupRoutes', err);
        })
        .finally(() => {
          this.groupRoutes.loading = false;
        });

      // Загрузка оператора группы

      let opQuery = _GROUP_OPERATOR_VIEW_GUID;

      opQuery += '?filter=';
      opQuery += `eq(field(".routeGroupId"), param("${this.selected.dsproutegroupsId}", "id"))`;

      await this.$store.dispatch('cache/removeItem', md5('core-read' + opQuery));

      prepareSinRequest('core-read', opQuery)
        .then(request => {
          const response = prepareSinResponse(request, {
            hideUnderscores: true,
          });

          if (response.length) {
            const operator = _copy(response[0]);
            this.operators.exist = operator;

            let qOperator = _OPERATOR_VIEW_GUID;

            qOperator += '?filter=';
            qOperator += `eq(field(".id"), param("${operator.operid}", "id"))`;

            // Данный запрос необходим для вставки в selected
            // Поскольку приходят слишком разные данные
            prepareSinRequest('core-read', qOperator)
              .then(request => {
                const oResponse = prepareSinResponse(request, {
                  hideUnderscores: true,
                });

                const data = _copy(oResponse[0]);
                data.isNew = false;

                this.operators.selected = data;
              });
          }
        })
        .catch(err => {
          console.error('FormGroup::loadGroupOperator', err);
        })
        .finally(() => {
          this.groupRoutes.loading = false;
        });
    },

    /// Таб 1

    // Обработка клика по "Добавить маршрут"
    onAddRouteClick() {
      this.currentTab = _ADD_ROUTE_TAB;
    },
    // Удаление маршрута группы
    onRemoveRouteGroup(item) {
      jet.confirm({
        title: 'Подвердите действие',
        msg: `Вы действительно хотите удалить "${item.name}"?`,
      }).then(answer => {
        if (answer) {
          // Если запись пришла с бека
          if (!item.isNew) {
            jet.http.post('/rpc?d=jsonRpc', {
              type: 'core-delete',
              query: _GROUP_ROUTES_VIEW_GUID,
              params: [
                httpParam('id', 'id', item.id),
              ],
            }).then(() => {
              this.table.data.splice(
                findObjectIndex(item, this.table.data),
                1
              );
            }).catch(err => {
              console.error('FormGroup::onRemoveRouteGroup', err);

              this.showMessage('warning', 'Не удалось удалить запись!');
            });
          } else {
            // Иначе просто удалим запись из таблицы
            this.table.data.splice(
              findObjectIndex(item, this.table.data),
              1
            );
          }
        }
      });
    },
    // Сохранение
    onSaveClick() {
      this.saving = true;

      const promises = [];

      const isNewOperator = this.operators.exist == null;

      jet.http.post('/rpc?d=jsonRpc', {
        type: `core-${isNewOperator ? 'create' : 'update'}`,
        query: _GROUP_OPERATOR_VIEW_GUID,
        params: [
          httpParam('id', 'id', isNewOperator ? uuidv4() : this.operators.exist.id),
          httpParam('routeGroupID', 'id', this.selected.dsproutegroupsId),
          httpParam('operId', 'id', this.operators.selected.id),
        ],
      }).catch(err => {
        console.error('FormGroup::onSaveClick::SaveOperator', err);

        this.showMessage('warning', 'Не удалось сохранить оператора');
      });

      this.table.data.forEach(it => {
        if (it.isNew) {
          promises.push(
            jet.http.post('/rpc?d=jsonRpc', {
              type: 'core-create',
              query: _GROUP_ROUTES_VIEW_GUID,
              params: [
                httpParam('id', 'id', uuidv4()),
                httpParam('routeGroupID', 'id', this.selected.dsproutegroupsId),
                httpParam('routeId', 'id', it.id),
              ],
            })
          );
        }
      });

      Promise.all(promises)
        .then(() => {
          this.collection.refresh();
          this.dialog = false;
        })
        .catch(err => {
          console.error('err', err);

          this.showMessage('warning', 'Не удалось сохранить изменения!');
        })
        .finally(() => {
          this.saving = false;
        });
    },

    /// Таб 2

    // При выборе маршрутов
    onRoutesSelect() {
      this.routes.selected.forEach(it => {
        const data = {
          num: it.routecode,
          name: it.routename,
          id: it.id,
          isNew: true,
        };

        this.table.data.push(data);
      });

      this.routes.selected = [];

      this.currentTab = _MAIN_TAB;
    },
    // При отмене выбора маршрутов
    onRoutesCancel() {
      this.routes.selected = [];

      this.currentTab = _MAIN_TAB;
    },
  },
}
</script>
